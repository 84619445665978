import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button, Modal } from "react-bootstrap";
import "../App.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import foto1 from "../logos/blog1.jpg";
import foto2 from "../logos/blog2.jpg";

const Blog = (props) => {

    const [style, setStyle] = useState({ display: "none" });

    const [show, setShow] = useState(false);

    const [data, setData] = useState({
        title: "",
        description: "",
        image: ""
    });

    const content = props.content;
    const font = props.font;

    const messages = [
        {
            title: content.Blog1,
            description: <>
                <p>{content.Blog12}</p>
                <p>{content.Blog13}</p>
                <p>{content.Blog14}</p>
                <p>{content.Blog15}</p>
                <p>{content.Blog16}</p>
            </>,
            image: foto1
        },
        {
            title: content.Blog2,
            description: <>
                <p>{content.Blog22}</p>
                <p>{content.Blog23}</p>
                <p>{content.Blog24}</p>
                <p>{content.Blog25}</p>
                <p>{content.Blog26}</p>
                <p>{content.Blog27}</p>
                <p>{content.Blog28}</p>
                <p>{content.Blog29}</p>
                <p>{content.Blog30}</p>
            </>,
            image: foto2
        },
        // {
        //     title: "Mehmet",
        //     description: "Hey there! I'm using WhatsApp.",
        //     image: foto1

        // }
    ]

    return (
        <section className="container">
            <h2 className={`display-4 lh-1 mb-5 mt-0 ${font} text-center`}>{content.Blog}</h2>
            <Row className="g-0 container">
                {messages.map(({ title, description, image }) => (
                    <Col xs={12} sm={{ span: 8 }} lg={{ span: 5}} key={title}>
                        <Card
                            size="lg"
                            onClick={() => {
                                setShow(true)
                                setData({ title: title, description: description, image: image })
                            }}
                            onMouseEnter={() => setStyle({ display: "block" })}
                            onMouseLeave={() => setStyle({ display: "none" })}
                            className="blog">
                            <img variant="top" src={image} max-width="323px" height="400px" alt="lolo" />
                            <Card.Body className="text-dark">
                                <Card.Title className={`${font}`}>{title}</Card.Title>
                                <Card.Text className={`${font}`}>
                                    Read More...
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Modal size="lg" show={show} onHide={() => setShow(false)} className="text-dark" >
                <Modal.Header closeButton>
                    <Modal.Title className={`${font}`}>{data.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`${font}`}>{data.description}</Modal.Body>
            </Modal>

        </section>
    );
};

export default Blog;
