import React, { useEffect, useState } from 'react';
import { BsPersonCheckFill } from 'react-icons/bs';
import { IoIosCreate } from 'react-icons/io';
import { AiFillShopping } from 'react-icons/ai';
import { IoNewspaperSharp } from 'react-icons/io5';
import { AiFillHome } from 'react-icons/ai';
// import { motion, useAnimation } from "framer-motion";
// import { useInView } from "react-intersection-observer";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

import 'react-vertical-timeline-component/style.min.css';

const Slides = (props) => {

    const theme = props.theme;
    const content = props.content;
    const font = props.font;


    return (
        <section className="">
            <div className="">
                <p></p>
                <h2 className={`display-4 lh-1 mb-5 mt-0 ${font} text-center`}>{content.How1}</h2>
            </div>
            <VerticalTimeline lineColor={ theme === "dark" ? "#fff" : "#212529" } >
                <VerticalTimelineElement 
                    className="vertical-timeline-element--work"
                    date="Step 1"
                    contentStyle={{ background: '#212529', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  #2b2926' }}
                    iconStyle={{ background: '#212529', color: '#fff' , borderRight: '1px solid #212529'}}
                    dateClassName={ theme === "dark" ? `${font} text-white` : `${font} text-dark` }
                    icon={<IoIosCreate />}
                >
                    <div style={{textAlignVertical: "right",textAlign: "right",}}>
                    <h3 className={`vertical-timeline-element-title ${font} `}>{content.How2}</h3>
                    <p className={`${font}`}>
                        {content.How3}
                    </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="Step 2"
                    contentStyle={{ background: '#E0AA22', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '10px solid  #E0AA22' }}
                    iconStyle={{ background: '#E0AA22', color: '#fff' }}
                    dateClassName={ theme === "dark" ? `${font} text-white` : `${font} text-dark` }
                    icon={<BsPersonCheckFill/>}
                >
                    <h3 className={`vertical-timeline-element-title ${font}`}>{content.How4}</h3>
                    <p className={`${font}`}>
                        {content.How5}
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="Step 3"
                    contentStyle={{ background: '#212529', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  #212529' }}
                    iconStyle={{ background: '#212529', color: '#fff' }}
                    dateClassName={ theme === "dark" ? `${font} text-white` : `${font} text-dark` }
                    icon={<AiFillShopping />}
                >
                    <div style={{textAlignVertical: "right",textAlign: "right",}}>
                    <h3 className={`vertical-timeline-element-title ${font}`}>{content.How6}</h3>
                    <p className={`${font}`}>
                        {content.How7}
                    </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="Step 4"
                    contentStyle={{ background: '#E0AA22', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  #E0AA22' }}
                    iconStyle={{ background: '#E0AA22', color: '#fff' }}
                    dateClassName={ theme === "dark" ? `${font} text-white` : `${font} text-dark` }
                    icon={<IoNewspaperSharp />}
                >
                    <h3 className={`vertical-timeline-element-title ${font}`}>{content.How8}</h3>
                    <p className={`${font}`}>
                        {content.How9}
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--education"
                    date="Step 5"
                    contentStyle={{ background: '#212529', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  #212529' }}
                    iconStyle={{ background: '#212529', color: '#fff' }}
                    dateClassName={ theme === "dark" ? `${font} text-white` : `${font} text-dark` }
                    icon={<AiFillHome />}
                >
                    <div style={{textAlignVertical: "right",textAlign: "right",}}>
                    <h3 className={`vertical-timeline-element-title ${font}`}>{content.How10}</h3>
                    <p className={`${font}`}>
                        {content.How11}
                    </p>
                    </div>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </section>
        // </motion.div>
    );
}

export default Slides;