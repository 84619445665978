import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
// import logo from '../logo.png'
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import MuiInput from '@mui/material/Input';
import Slider from '@mui/material/Slider';
import { createTheme } from '@mui/material/styles';
import 'bootstrap/dist/css/bootstrap.min.css';

const Input = styled(MuiInput)`
  width: 80px;
`;

const theme = createTheme({
    palette: {
        primary: {
            main: "#333",
        }
    },
});

const IOSSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? '#E0AA22' : '#E0AA22',
    height: 5,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
        height: 25,
        width: 25,
        backgroundColor: '#E0AA22',
        boxShadow: iOSBoxShadow,
        '&:focus, &:hover, &.Mui-active': {
            boxShadow:
                '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    '& .MuiSlider-valueLabel': {
        fontSize: 15,
        fontWeight: 'normal',
        top: -6,
        backgroundColor: 'unset',
        color: '#fff',
        '&:before': {
            color: '#fff',
            display: 'none',
        },
        '& *': {
            color: '#fff',
        },
    },
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-rail': {
        opacity: 1,
        height: 5,
        backgroundColor: '#fff',
    },
    '& .MuiSlider-mark': {
        color: '#fff',
        height: 8,
        width: 1,
        '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: 'currentColor',
            color: '#fff',
        },
    },
    '& .MuiSlider-markLabel': {
        color: '#fff',
        fontSize: 15,
        fontWeight: 'normal',
        '&.MuiSlider-markLabelActive': {
            color: '#fff',
        },
    },
}));

const marks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 12500,
    },
    {
        value: 25000,
    },
    {
        value: 37500,
    },
    {
        value: 50000,
        label: '50000',
    },
];

const marks2 = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 6,
    },
    {
        value: 12,
    },
    {
        value: 18,
    },
    {
        value: 24,
    },
    {
        value: 30,
    },
    {
        value: 36,
        label: '36',
    }
];

const iOSBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const Calculator = (props) => {

    const content = props.content;
    const font = props.font;

    const [loanAmount, setLoanAmount] = React.useState(500);
    const [duration, setDuration] = React.useState(6);
    const [interestRate, setInterestRate] = React.useState(3.5);
    const [monthlyRepayment, setMonthlyRepayment] = React.useState(0);
    const [totalRepayment, setTotalRepayment] = React.useState(0);

    const handleSliderChange = (event, newValue) => {
        setLoanAmount(newValue);
        calc();
    };

    const handleSliderChange2 = (event, newValue) => {
        setDuration(newValue);
        calc();
    };

    const handleInputChange = (event) => {
        setLoanAmount(event.target.value === '' ? '' : Number(event.target.value));
        calc();
    };

    const handleInputChange2 = (event) => {
        setDuration(event.target.value === '' ? '' : Number(event.target.value));
        calc();
    };

    const handleBlur = () => {
        if (loanAmount < 0) {
            setLoanAmount(0);
            calc();
        } else if (loanAmount > 50000) {
            setLoanAmount(50000);
            calc();
        }
    };

    const handleBlur2 = () => {
        if (duration < 0) {
            setDuration(0);
            calc();
        } else if (duration > 36) {
            setDuration(36);
            calc();
        }
    };

    const handle = async (e) => {
        setInterestRate(e.target.value === '' ? 3.5 : Number(e.target.value));
        calc();
    }

    const calc = () => {
        const data1 = interestRate * loanAmount / duration + loanAmount / duration;
        const data2 = interestRate * loanAmount + loanAmount;
        setMonthlyRepayment(data1);
        setTotalRepayment(data2);
    }

    return (
        <section id="calc" >
            <Container maxWidth="false" disableGutters="true" fixed className='dark'
                sx={
                    {
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundAttachment: 'fixed',
                        maxwidth: "100%",
                        height: "100%",
                        position: 'relative',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        paddingTop: '100px',
                        paddingBottom: '100px'
                    }
                }>
                <div className="container px-5n">
                    <h3 className={`text-center display-4 lh-1 mb-2 mt-0 ${font}`} >{content.Calculator1}</h3>
                </div><br />
                <div className="container text-center mt-5">
                    <Row>
                        <Col md={6}>
                            <Container fixed >
                                <h2 className={`${font} mb-3`}>{content.Calculator2}</h2>
                                <Input
                                    className='mb-5 text-white'
                                    value={loanAmount}
                                    size="small"
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    inputProps={{
                                        step: 10,
                                        min: 0,
                                        max: 50000,
                                        type: 'number',
                                        'aria-labelledby': 'input-slider',
                                    }}
                                />
                                <IOSSlider
                                    min={0}
                                    max={50000}
                                    aria-label="ios slider"
                                    defaultValue={500}
                                    marks={marks}
                                    value={typeof loanAmount === 'number' ? loanAmount : 0}
                                    onChange={handleSliderChange}
                                    valueLabelDisplay="on"
                                />
                            </Container>
                        </Col>
                        <Col md={6} mt={100}>
                            <Container fixed>
                                <h2 className={`${font} mb-3`}>{content.Calculator3}</h2>
                                <Input
                                    className='mb-5 text-white'
                                    value={duration}
                                    size="small"
                                    onChange={handleInputChange2}
                                    onBlur={handleBlur2}
                                    inputProps={{
                                        step: 10,
                                        min: 0,
                                        max: 36,
                                        type: 'number',
                                        'aria-labelledby': 'input-slider',
                                    }}
                                />
                                <IOSSlider
                                    min={0}
                                    max={36}
                                    aria-label="ios slider"
                                    defaultValue={6}
                                    marks={marks2}
                                    value={typeof duration === 'number' ? duration : 0}
                                    onChange={handleSliderChange2}
                                    valueLabelDisplay="on"
                                />
                            </Container>
                        </Col>
                    </Row>
                    <Container fixed>
                        <h2 className={`${font} mb-4 mt-4`}>{content.Calculator4}</h2>
                        <Input className="mb-5 text-white" placeholder='3.5%' onChange={handle} />
                    </Container>
                    <div className="container mb-2 mt-5">
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <h4 className={`${font} mb-4`}>{content.Calculator5}</h4>
                                    <h2><b>{monthlyRepayment.toFixed(2)}€</b></h2>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <h4 className={`${font} mb-4`}>{content.Calculator6}</h4>
                                    <h2><b>{totalRepayment.toFixed(2)}€</b></h2>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <h4 className={`${font} mb-4`}>{content.Calculator4}</h4>
                                    <h2><b>{interestRate}%</b></h2>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <h4 className={`${font} mb-4`}>{content.Calculator7}</h4>
                                    <h2><b>{interestRate}%</b></h2>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </section>
        // </motion.div>
    );
}

export default Calculator;

